@import 'sass/variables/breakpoints';

.not-found {
  background: #b7d6da;
  padding-top: 25vh;
  padding-bottom: 25vh;
  height: 100vh;
  color: #34495e;

  @media screen and ($media-screen-sm-min) {
    background-size: 100%;
    background: url('/static/assets/img/page404.png') no-repeat center center
      fixed;
    background-size: cover;
    padding: 0;

    .row {
      margin-top: 40vh;
    }
  }

  .tagline {
    line-height: 45px;
  }

  .sub-tagline {
    line-height: 30px;
    font-weight: normal;
  }

  .go-to-homepage {
    margin-top: 15px;
    padding: 10px 15px;
    width: 100%;

    @media screen and ($media-screen-sm-min) {
      width: 200px;
    }
  }
}
