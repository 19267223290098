// Tipple site mixins
@import 'sass/variables/breakpoints';

@mixin btn() {
  border-radius: var(--btn-border-radius);
  line-height: 1;
  padding: var(--btn-padding);

  @media screen and ($media-screen-sm-min) {
    padding: var(--btn-padding-sm);
  }
}

@mixin cta-shadow() {
  box-shadow: 5px 10px 12px rgba(0, 0, 0, 0.03);
}

@mixin shadow-collection-item() {
  // box-shadow: 5px 10px 12px rgba(0, 0, 0, 0.03), 0px 2px 8px rgba(237, 202, 145, 0.04);
  box-shadow:
    3px 3px 8px #f5f5f8,
    0px 2px 6px rgba(0, 0, 0, 0.1);
}

@mixin cta-shadow-hover() {
  box-shadow: 5px 10px 12px rgba(0, 0, 0, 0.03);
  position: relative;

  &::after {
    box-shadow: 5px 6px 15px rgba(0, 0, 0, 0.03);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.25s ease-in-out;
    width: 100%;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

@mixin delivery-address() {
  background: #ffffff;
  border: 1px solid $colour-grey-lighter;
  border-radius: 3px 0px 0px 3px;
  color: $colour-body-text;
  font-size: 16px;
  height: 50px;
  line-height: 1.2;
  padding: 15px 20px;
  width: 100%;
}

@mixin modal-overlay() {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  padding: 1.2rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}
