// @import 'sass/variables/breakpoints';
@import 'sass/variables/colours';
// @import 'sass/mixins/tipple';

.primary {
  flex: 0;
  margin-right: 30px;
  position: relative;
}

.menu {
  background: $colour-white;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.23);
  left: 6px;
  list-style: none;
  min-width: 160px;
  padding: 10px 14px;
  position: absolute;
  top: calc(100% + 6px);
}

.item {
  color: var(--primary);
  cursor: pointer;
  line-height: 1.8;
}

.button {
  background: none;
  border: none;
  display: block;
  height: 32px;
}

.icon {
  display: flex;
  flex-direction: column;
  height: 33px;
  justify-content: space-around;
  padding-left: 3px;
  width: 32px;

  .top,
  .mid,
  .bot {
    background: var(--primary);
    display: block;
    height: 3px;
    position: relative;
    width: calc(100% - 6px);

    &::before {
      background: var(--primary);
      border-radius: 100%;
      content: '';
      display: block;
      height: 100%;
      left: -3px;
      position: absolute;
      top: 0;
      width: 6px;
    }

    &::after {
      background: var(--primary);
      border-radius: 100%;
      content: '';
      display: block;
      height: 100%;
      right: -3px;
      position: absolute;
      top: 0;
      width: 6px;
    }
  }

  .mid {
    width: calc(85% - 6px);
  }
}
