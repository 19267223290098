.spinner {
  position: absolute;
  top: 20%;
  bottom: 0;
  left: 0;
  right: 0;

  :global {
    .loader {
      position: relative;
      margin: 0 auto;
      width: 72px;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }

    .circular {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation:
        dash 1.5s ease-in-out infinite,
        color 6s ease-in-out infinite;
      stroke-linecap: round;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }

    @keyframes color {
      100%,
      0% {
        stroke: var(--primary);
      }
      40% {
        stroke: #ffd249;
      }
      66% {
        stroke: var(--secondary);
      }
      80%,
      90% {
        stroke: #c8eafb;
      }
    }
  }
}
