@import 'sass/_colours';
@import 'sass/_mixins';

.wrap {
  background: var(--navbar-bg);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  display: none;
  left: -15px;
  padding: 7px 15px 13px;
  position: absolute;
  right: -15px;
  top: 72px;
  z-index: -1;

  @include media-breakpoint-up(md) {
    box-shadow: none;
    display: block;
    left: auto;
    padding: 7px 0;
    position: relative;
    right: auto;
    top: auto;
    z-index: 1;
  }

  &.mobile {
    display: block;
  }
}

.title {
  color: var(--text-colour-headings);
  display: block;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 2px;
}

.address {
  color: var(--text-colour-body);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0;

  i {
    color: var(--primary);
    margin-left: 8px;
  }
}
