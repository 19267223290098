@import 'sass/_colours';

.footer {
  background: #212121;
  padding: 24px 0;
  clear: both;

  a {
    color: $colour-white;
  }

  a:hover {
    color: #757575;
    text-decoration: none;
  }

  .row-visa {
    margin-bottom: 50px;
    margin-top: 50px;

    img {
      margin: 2px;
    }
  }
}

:global {
  .footer-styled {
    background: #212121;
    padding: 24px 0;
    clear: both;

    a {
      color: $colour-white;
    }

    a:hover {
      color: #757575;
      text-decoration: none;
    }

    .row-visa {
      margin-bottom: 50px;
      margin-top: 50px;

      img {
        margin: 2px;
      }
    }
  }
}
