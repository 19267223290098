// DO NOT USE ANYMORE

$colour-white: #fff;
$colour-black: #000;

$colour-grey-dark: rgba(0, 0, 0, 0.65);
$colour-grey-light: rgba(0, 0, 0, 0.38);
$colour-grey-darkest: #262626;

$colour-spritz: #f57153;
$colour-champagne: #faf7f2;
$colour-brut: #faf7f2;
$colour-navy: #0b2146;

$colour-success: #4caf50;

$colour-headings: $colour-navy;
$colour-body-text: $colour-grey-darkest;
$colour-body-text-light: #757575;
$colour-section-border: #eee;

$colour-grey-lighter: #d6d6d6;
$colour-grey-lightest: #f5f5f8;
